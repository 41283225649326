import {
  Flex,

  Text,
  Image,
  Box,
  SimpleGrid,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import "../section.css";

import { HashLink } from "react-router-hash-link";
import { List, ListItem} from "@chakra-ui/react";
import Underline from "../../../assets/images/HomePage/underline.webp";
import Servicerobo from "../../../assets/images/HomePage/Homeservices-final.webp";
import Blk from "../../../assets/images/HomePage/blk.webp";
import ARVR from "../../../assets/images/HomePage/ar.webp";

import Soft from "../../../assets/images/HomePage/soft.webp";

function HomeServiceSection(props) {
  const [openIndex, setOpenIndex] = useState(null);

  const handleBoxClick = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };
  const boxHeightopen = useBreakpointValue({
    base: "400px",
    md: "400px",
    lg: "530px",
  });

  const boxHeightclose = useBreakpointValue({
    base: "230px",
    md: "230px",
    lg: "300px",
  });

  return (
    // Our Service section(new)
    <Flex
      zIndex={4}
      mt={{ base: "0px", md: "0", lg: "0" }}
      py={{ base: "20px", md: "", lg: "" }}
      height={{ base: "fit-content", md: "fit-content", lg: "fit-content" }}
      width={"100%"}
    >
      <Flex
        flexDir={"column"}
        mb={{ base: "100px", md: "200px", lg: "300px" }}
        width={"100%"}
      >
        {/* Heading */}
        <Flex my={{ base: "30px", md: "10px", lg: "10px" }} mx={"auto"}>
          <Image
            mt={{ base: "6px", lg: "15px" }}
            height={"40px"}
            width={"70px"}
            src={Underline}
            alt="underline"
          ></Image>
          <Text
            className="heading"
            color={"#94DAF8"}
            fontSize={{ base: "24px", md: "38px", lg: "45px" }}
          >
            Our Services
          </Text>
          <Image
            mt={{ base: "6px", lg: "15px" }}
            height={"40px"}
            width={"70px"}
            src={Underline}
            alt="underline"
          ></Image>
        </Flex>

        {/* Content */}
        <Flex width={"100%"} mx={"auto"} mt={"12%"}>
          <SimpleGrid
            mx={"auto"}
            // width={"fit-content"}
            width={{ base: "80%", md: "65%", lg: "80%" }}
            columns={{ base: 1, md: 1, lg: 3 }}
            spacing={{ base: "20px", md: "20px", lg: "10px" }}
          >
            {/* Blockchain */}
            <Box
              _hover={{
                backgroundColor: "rgba(0, 0, 0, 0.3)",
              }}
              cursor={"pointer"}
              style={{
                height: 1 === openIndex ? boxHeightopen : boxHeightclose,
                transition: "height 0.4s linear",
              }}
              overflow={"hidden"}
              backgroundColor={"rgba(0, 0, 0, 0.15)"}
              borderRadius={"5px"}
              backdropFilter="auto"
              backdropBlur="2px"
              boxShadow={"0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"}
              onClick={() => handleBoxClick(1)}
              // width={{ base: "135px", md: "170px", lg: "290px" }}
            >
              <Flex
                flexDir={"column"}
                p={{ base: "25px", md: "40px", lg: "40px" }}
              >
                <Image
                  alt="Trackgenesis blockchain"
                  className="floatinganime"
                  mx={"auto"}
                  width={{ base: "130px", md: "130px", lg: "200px" }}
                  src={Blk}
                ></Image>
                <Text
                  textAlign={"center"}
                  mt={"20px"}
                  mx={"auto"}
                  fontSize={{ base: "13px", md: "15px", lg: "17px" }}
                  className="content_font"
                  color={"#94DAF8"}
               
                >
                  Blockchain Development
                </Text>
                <Text
                  style={{
                    opacity: 1 === openIndex ? 1 : 0,
                    transition: "opacity 0.1s linear",
                    display: 1 === openIndex ? "flex" : "none",
                  }}
                  mt={"20px"}
                  // maxW={"160px"}
                  fontSize={{ base: "11px", md: "11px", lg: "15px" }}
                  className="content_font"
                  color={"white"}
                >
                  <List width={"300px"} mx="auto" spacing={3}>
                    <HashLink smooth to={"/services/blockchain#blockchain"}>
                      {" "}
                      <ListItem
                        mt={"3px"}
                        _hover={{
                          transform: "scale(1.05)",
                        }}
                        transition="transform 0.2s ease-in-out"
                      >
                        ✅ Blockchain consultation and integration
                      </ListItem>
                    </HashLink>

                    <HashLink smooth to={"/services/blockchain#blockchain"}>
                      {" "}
                      <ListItem
                        mt={"3px"}
                        _hover={{
                          transform: "scale(1.05)",
                        }}
                        transition="transform 0.2s ease-in-out"
                      >
                        ✅ Dapp development
                      </ListItem>
                    </HashLink>

                    <HashLink smooth to={"/services/blockchain#blockchain"}>
                      {" "}
                      <ListItem
                        mt={"3px"}
                        _hover={{
                          transform: "scale(1.05)",
                        }}
                        transition="transform 0.2s ease-in-out"
                      >
                        ✅Traceability & compliance Platform
                      </ListItem>
                    </HashLink>

                    <HashLink smooth to={"/services/blockchain#blockchain"}>
                      {" "}
                      <ListItem
                        mt={"3px"}
                        _hover={{
                          transform: "scale(1.05)",
                        }}
                        transition="transform 0.2s ease-in-out"
                      >
                        ✅ NFT development- NFT integration to marketplace
                        (digital collectibles)
                      </ListItem>
                    </HashLink>

                    <HashLink smooth to={"/services/blockchain#blockchain"}>
                      {" "}
                      <ListItem
                        mt={"3px"}
                        _hover={{
                          transform: "scale(1.05)",
                        }}
                        transition="transform 0.2s ease-in-out"
                      >
                        ✅Smart contract development
                      </ListItem>
                    </HashLink>

                    <HashLink smooth to={"/services/blockchain#blockchain"}>
                      {" "}
                      <ListItem
                        mt={"3px"}
                        _hover={{
                          transform: "scale(1.05)",
                        }}
                        transition="transform 0.2s ease-in-out"
                      >
                        ✅ Blockchain Identity/ DIDs DPIN{" "}
                      </ListItem>
                    </HashLink>

                    <HashLink smooth to={"/services/blockchain#blockchain"}>
                      {" "}
                      <ListItem
                        mt={"3px"}
                        _hover={{
                          transform: "scale(1.05)",
                        }}
                        transition="transform 0.2s ease-in-out"
                      >
                        ✅ DPIN{" "}
                      </ListItem>
                    </HashLink>
                  </List>
                </Text>
              </Flex>
            </Box>

            {/* ARVR */}
            <Box
              _hover={{
                backgroundColor: "rgba(0, 0, 0, 0.3)",
              }}
              cursor={"pointer"}
              style={{
                height: 2 === openIndex ? boxHeightopen : boxHeightclose,
                transition: "height 0.4s linear",
              }}
              overflow={"hidden"}
              backgroundColor={"rgba(0, 0, 0, 0.15)"}
              borderRadius={"5px"}
              backdropFilter="auto"
              backdropBlur="2px"
              boxShadow={"0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"}
              onClick={() => handleBoxClick(2)}
              // width={{ base: "135px", md: "170px", lg: "290px" }}
            >
              <Flex
                flexDir={"column"}
                p={{ base: "25px", md: "40px", lg: "40px" }}
              >
                <Image
                  alt="Trackgenesis arvr"
                  className="floatinganime"
                  mx={"auto"}
                  width={{ base: "130px", md: "130px", lg: "200px" }}
                  src={ARVR}
                ></Image>
                <Text
                  textAlign={"center"}
                  mt={"20px"}
                  mx={"auto"}
                  fontSize={{ base: "13px", md: "15px", lg: "17px" }}
                  className="content_font"
                  color={"#94DAF8"}
               
                >
                  Extended Reality
                </Text>
                <Text
                  style={{
                    opacity: 2 === openIndex ? 1 : 0,
                    transition: "opacity 0.1s linear",
                    display: 2 === openIndex ? "flex" : "none",
                  }}
                  mt={"20px"}
                  // maxW={"160px"}
                  fontSize={{ base: "11px", md: "11px", lg: "15px" }}
                  className="content_font"
                  color={"white"}
                >
                  <List width={"300px"} mx="auto" spacing={3}>
                    <HashLink smooth to={"/services/ar-vr#arvr"}>
                      {" "}
                      <ListItem
                        mt={"3px"}
                        _hover={{
                          transform: "scale(1.05)",
                        }}
                        transition="transform 0.2s ease-in-out"
                      >
                        ✅ AR product Demonstrations
                      </ListItem>
                    </HashLink>

                    <HashLink smooth to={"/services/ar-vr#arvr"}>
                      {" "}
                      <ListItem
                        mt={"3px"}
                        _hover={{
                          transform: "scale(1.05)",
                        }}
                        transition="transform 0.2s ease-in-out"
                      >
                        ✅ AR learning tools
                      </ListItem>
                    </HashLink>

                    <HashLink smooth to={"/services/ar-vr#arvr"}>
                      {" "}
                      <ListItem
                        mt={"3px"}
                        _hover={{
                          transform: "scale(1.05)",
                        }}
                        transition="transform 0.2s ease-in-out"
                      >
                        ✅ AR Business card
                      </ListItem>
                    </HashLink>

                    <HashLink smooth to={"/services/ar-vr#arvr"}>
                      {" "}
                      <ListItem
                        mt={"3px"}
                        _hover={{
                          transform: "scale(1.05)",
                        }}
                        transition="transform 0.2s ease-in-out"
                      >
                        ✅ Location-Based AR Advertising
                      </ListItem>
                    </HashLink>

                    <HashLink smooth to={"/services/ar-vr#arvr"}>
                      {" "}
                      <ListItem
                        mt={"3px"}
                        _hover={{
                          transform: "scale(1.05)",
                        }}
                        transition="transform 0.2s ease-in-out"
                      >
                        ✅ Immersive Storytelling
                      </ListItem>
                    </HashLink>

                    <HashLink smooth to={"/services/ar-vr#arvr"}>
                      {" "}
                      <ListItem
                        mt={"3px"}
                        _hover={{
                          transform: "scale(1.05)",
                        }}
                        transition="transform 0.2s ease-in-out"
                      >
                        ✅ VR Fire n Safety Training
                      </ListItem>
                    </HashLink>

                    <HashLink smooth to={"/services/ar-vr#arvr"}>
                      {" "}
                      <ListItem
                        mt={"3px"}
                        _hover={{
                          transform: "scale(1.05)",
                        }}
                        transition="transform 0.2s ease-in-out"
                      >
                        ✅ VR environment design and creation
                      </ListItem>
                    </HashLink>

                    <HashLink smooth to={"/services/ar-vr#arvr"}>
                      {" "}
                      <ListItem
                        mt={"3px"}
                        _hover={{
                          transform: "scale(1.05)",
                        }}
                        transition="transform 0.2s ease-in-out"
                      >
                        ✅ Metaverse development with multiplayer interaction
                      </ListItem>
                    </HashLink>
                  </List>
                </Text>
              </Flex>
            </Box>
         
           

            {/* Software Solns */}
            <Box
              // mt={{ base: "100px", md: "100px", lg: "0px" }}
              _hover={{
                backgroundColor: "rgba(0, 0, 0, 0.3)",
              }}
              cursor={"pointer"}
              overflow={"hidden"}
              backgroundColor={"rgba(0, 0, 0, 0.15)"}
              borderRadius={"5px"}
              backdropFilter="auto"
              backdropBlur="2px"
              boxShadow={"0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"}
              onClick={() => handleBoxClick(4)}
              style={{
                height: 4 === openIndex ? boxHeightopen : boxHeightclose,
                transition: "height 0.4s linear",
              }}
              // width={{ base: "135px", md: "170px", lg: "230px" }}
            >
              <Flex
                flexDir={"column"}
                p={{ base: "25px", md: "40px", lg: "40px" }}
              >
                <Image
                  alt="TrackGeneis Software Solutions"
                  className="floatinganime"
                  mx={"auto"}
                  width={{ base: "130px", md: "130px", lg: "200px" }}
                  src={Soft}
                ></Image>
                <Text
                  textAlign={"center"}
                  mt={"20px"}
                  mx={"auto"}
                  fontSize={{ base: "13px", md: "15px", lg: "17px" }}
                  className="content_font"
                  color={"#94DAF8"}
             
                >
                  Software development
                </Text>
                <Text
                  style={{
                    opacity: 4 === openIndex ? 1 : 0,
                    transition: "opacity 0.1s linear",
                    display: 4 === openIndex ? "flex" : "none",
                  }}
                  mt={"20px"}
                  // maxW={"160px"}
                  fontSize={{ base: "11px", md: "11px", lg: "15px" }}
                  className="content_font"
                  color={"white"}
                >
                  <List width={"300px"} mx="auto" spacing={3}>
                    <HashLink
                      smooth
                      to={"/services/software-development#softsection"}
                    >
                      {" "}
                      <ListItem
                        mt={"3px"}
                        _hover={{
                          transform: "scale(1.05)",
                        }}
                        transition="transform 0.2s ease-in-out"
                      >
                        ✅ Custom software{" "}
                      </ListItem>
                    </HashLink>

                    <HashLink
                      smooth
                      to={"/services/software-development#softsection"}
                    >
                      {" "}
                      <ListItem
                        mt={"3px"}
                        _hover={{
                          transform: "scale(1.05)",
                        }}
                        transition="transform 0.2s ease-in-out"
                      >
                        ✅ UI/UX design{" "}
                      </ListItem>
                    </HashLink>

                    <HashLink
                      smooth
                      to={"/services/software-development#softsection"}
                    >
                      {" "}
                      <ListItem
                        mt={"3px"}
                        _hover={{
                          transform: "scale(1.05)",
                        }}
                        transition="transform 0.2s ease-in-out"
                      >
                        ✅ Mobile app development{" "}
                      </ListItem>
                    </HashLink>
                    <HashLink
                      smooth
                      to={"/services/software-development#softsection"}
                    >
                      {" "}
                      <ListItem
                        mt={"3px"}
                        _hover={{
                          transform: "scale(1.05)",
                        }}
                        transition="transform 0.2s ease-in-out"
                      >
                        ✅ Web app development{" "}
                      </ListItem>
                    </HashLink>
                  </List>
                </Text>
              </Flex>
            </Box>
          </SimpleGrid>

          <Image
            alt="our service robo of blockchain"
            zIndex={-1}
            mt={{ base: "320px", md: "170px", lg: "200px" }}
            ml={{ base: "20%", md: "54%", lg: "54%" }}
            display={{base:"none",md:"none",lg:"flex"}}
            width={{ base: "240px", md: "300px", lg: "400px" }}
            position={"absolute"}
            src={Servicerobo}
          ></Image>
        </Flex>
      </Flex>
    </Flex>
    //
  );
}

export default HomeServiceSection;
