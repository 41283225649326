import {  Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import Mobilenavbar from "../../components/sections/Mobilenavbar";
import Navbar from "../../components/sections/Navbar";
import Footer from "../../components/sections/Footer";


import Underline from "../../assets/images/HomePage/underline.webp";


import { Helmet } from "react-helmet";

import ShoppingExperience from "../../components/sections/ArVr/ShoppingExperience";
import BlockchainTechnology from "../../components/sections/ArVr/BlockchainTechnology";
import Maintenance from "../../components/sections/ArVr/Maintenance";
import Education from "../../components/sections/ArVr/Education";
import Presentation from "../../components/sections/ArVr/Presentation";
import ARVRheader from "../../assets/images/Ar&VrPage/arvrheader.webp";
import ARnew from "../../assets/images/Ar&VrPage/newar.gif";
import VRnew from "../../assets/images/Ar&VrPage/newvr.gif";
import MRnew from "../../assets/images/Ar&VrPage/newmr.gif";
import VRTraining from "../../components/sections/ArVr/VRTraining";
import VirtualMeeting from "../../components/sections/ArVr/VirtualMeeting";
import Gaming from "../../components/sections/ArVr/Gaming";
import VRLearning from "../../components/sections/ArVr/VRLearning";
import ParticleBackground from "../../components/sections/home/ParticleBackground";
import ParticleTrail from "../../components/sections/home/ParticleTrail";
import BlkRing from "../../assets/images/IndustrysolutionsPage/ring.webp";
import CommonButton from "../../components/CommonButton";
import { HashLink } from "react-router-hash-link";

function ArVr(props) {


  return (
    <>
      {/* helmet */}
      <Helmet>
        <title>
          Top AR & VR App Development Company | TrackGenesis® | Scotland UK
        </title>
        <meta
          name="title"
          content="Top AR & VR App Development Company | TrackGenesis®️ | Scotland UK "
        />
        <meta
          name="description"
          content="We create immersive experiences in augmented and virtual reality that reflects the client's imagination and requirements.  "
        />
        <link
          href="https://trackgenesis.com/services/ar-vr"
          rel="canonical"
          content="max-age: 1209600, no-cache, no-store, must-revalidate"
        />

        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-197747980-1"
        ></script>
      </Helmet>
      {/* helmet */}
      <Flex
        backgroundColor={"black"}
        flexDirection={"column"}
        overflowX={"hidden"}
        id="arvr"
      >
        {/* new heading Section */}
        <Flex>
          <Image
            alt=" Trackgenesis Extended Reality"
            ml={{ base: "-50px", md: "-40px", lg: "0px" }}
            width={{ base: "270px", md: "400px", lg: "500px" }}
            src={ARVRheader}
          ></Image>
          <Text
            mt={"auto"}
            className="heading"
            mb={{ base: "15px", md: "15px", lg: "30px" }}
            color={"#94DAF8"}
            fontSize={{ base: "30px", md: "45px", lg: "50px" }}
            ml={{ base: "-19%", md: "-19%", lg: "-10%" }}
          >
            Extended Reality
          </Text>
        </Flex>

        <Navbar />
        <Mobilenavbar />

        {/* new content */}

        <ParticleTrail />
        <Flex
          flexDir={"column"}
          position={"relative"}
          className="mainborder"
          mx={{ base: "10px", md: "15px", lg: "30px" }}
          backgroundColor={"rgba(27, 21, 78, 0.38)"}
          backdropFilter="auto"
          backdropBlur="4px"
          pb={"100px"}
        >
         
        

          {/* AR intro */}

          <Flex
            id="augmentedreality"
            my={{ base: "30px", md: "30px", lg: "90px" }}
            // p={"60px"}
            mx={"auto"}
            width={{ base: "80%", md: "80%", lg: "900px" }}
            flexDir={{
              base: "column-reverse",
              md: "column-reverse",
              lg: "row",
            }}
          >
            <Flex
              flexDir={"column"}
              mt={{ base: "-60px", md: "-50px", lg: "0px" }}
              justifyContent={"center"}
              alignItems={"center"}
              backgroundColor={"rgba(0, 0, 0, 0.15)"}
              borderRadius={"5px"}
              backdropFilter="auto"
              backdropBlur="2px"
              boxShadow={"0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"}
            >
              <Flex
                flexDir={"column"}
                mr={"auto"}
                ml={{ base: "20px", md: "20px", lg: "45px" }}
                mt={{ base: "100px", md: "100px", lg: "30px" }}
              >
                <Text
                  className="heading"
                  color={"#94DAF8"}
                  fontSize={{ base: "22px", md: "22px", lg: "25px" }}
                >
                  Grow Your Business Using Augmented Reality
                </Text>
                <Image
                  mt={"-10px"}
                  ml={"-10px"}
                  width={"70px"}
                  src={Underline}
                  alt="underline"
                ></Image>
              </Flex>
              <Text
                pr={{ base: "20px", md: "30px", lg: "150px" }}
                pl={{ base: "20px", md: "30px", lg: "50px" }}
                py={{ base: "30px", md: "30px", lg: "30px" }}
                fontSize={{ base: "14px", md: "14px", lg: "17px" }}
                color={"white"}
                className="content_font"
                fontWeight={"light"}
                maxW={{ base: "600px", md: "600px", lg: "800px" }}
              >
                Augmented Reality offers the physical world a digital twist.
                From a piece of information to a digital entity, you can add
                spectacular effects and characteristics to the actual world. Our
                augmented reality app drives increased user engagement and
                elevates your brand presence in the digital world.
                TrackGenesis’s AR innovations provide unbelievable experiences
                and build your space among customers for a lifetime.
              </Text>

              <Flex mr={"auto"} ml={"50px"} mt={"-10px"} mb={"20px"}>
                <HashLink smooth to={"/products#arbook"}>
                  <CommonButton btn="View Demo" />
                </HashLink>
              </Flex>
            </Flex>

            <Image
              mr={{ base: "auto", md: "auto", lg: "0px" }}
              alt=" Trackgenesis AR Augmented Reality"
              ml={{ base: "auto", md: "auto", lg: "-150px" }}
              zIndex={2}
              width={{ base: "180px", md: "180px", lg: "280px" }}
              objectFit={"contain"}
              src={ARnew}
            ></Image>
            <Image
              alt=" Trackgenesis Extended Reality"
              width={{ base: "210px", md: "210px", lg: "330px" }}
              objectFit={"contain"}
              zIndex={1}
              mr={{ base: "auto", md: "auto", lg: "0px" }}
              mb={{ base: "-180px", md: "-180px", lg: "0px" }}
              ml={{ base: "auto", md: "auto", lg: "-300px" }}
              src={BlkRing}
            ></Image>
          </Flex>

          {/* AR Content */}

          {/* Shopping Experience */}
          <ShoppingExperience />

          {/* Maintenance */}
          <Maintenance />

          {/* Education */}
          <Education />

          {/* Service*/}
          <BlockchainTechnology />

          {/* Website */}
          <Presentation />

          {/* VR Intro */}
          <Flex id="virtualReality" flexDir={"column"}>
            <Flex
              my={{ base: "30px", md: "30px", lg: "90px" }}
              // p={"60px"}
              mx={"auto"}
              width={{ base: "80%", md: "80%", lg: "900px" }}
              flexDir={{
                base: "column-reverse",
                md: "column-reverse",
                lg: "row",
              }}
            >
              <Flex
                flexDir={"column"}
                mt={{ base: "-60px", md: "-50px", lg: "0px" }}
                justifyContent={"center"}
                alignItems={"center"}
                backgroundColor={"rgba(0, 0, 0, 0.15)"}
                borderRadius={"5px"}
                backdropFilter="auto"
                backdropBlur="2px"
                boxShadow={"0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"}
              >
                <Flex
                  flexDir={"column"}
                  mr={"auto"}
                  ml={{ base: "20px", md: "20px", lg: "45px" }}
                  mt={{ base: "100px", md: "100px", lg: "30px" }}
                >
                  <Text
                    className="heading"
                    color={"#94DAF8"}
                    fontSize={{ base: "22px", md: "22px", lg: "25px" }}
                    pr={"30px"}
                  >
                    Create Immersive Experiences using Virtual Reality
                  </Text>
                  <Image
                    mt={"-10px"}
                    ml={"-10px"}
                    width={"70px"}
                    src={Underline}
                    alt="underline"
                  ></Image>
                </Flex>
                <Text
                  pr={{ base: "20px", md: "30px", lg: "150px" }}
                  pl={{ base: "20px", md: "30px", lg: "50px" }}
                  py={{ base: "30px", md: "30px", lg: "30px" }}
                  fontSize={{ base: "14px", md: "14px", lg: "17px" }}
                  color={"white"}
                  className="content_font"
                  fontWeight={"light"}
                  maxW={{ base: "600px", md: "600px", lg: "800px" }}
                >
                  Step into the virtual world and get connected to people from
                  around the world within the comfort of your home. At
                  TrackGenesis, we work hand-in-hand with the latest
                  technological developments to build the perfect virtual world
                  and ensure the finest VR experience. Our VR developers and 3D
                  model artists are ready to create magical experiences for your
                  customers beyond location constraints.
                </Text>
                <Flex mr={"auto"} ml={"50px"} mt={"-10px"} mb={"20px"}>
                  <HashLink smooth to={"/case-study#fire"}>
                    <CommonButton btn="View Demo" />
                  </HashLink>
                </Flex>
              </Flex>

              <Image
                mr={{ base: "auto", md: "auto", lg: "0px" }}
                alt="VR Virtual Reality TrackGenesis"
                ml={{ base: "auto", md: "auto", lg: "-150px" }}
                zIndex={2}
                width={{ base: "180px", md: "180px", lg: "280px" }}
                objectFit={"contain"}
                src={VRnew}
              ></Image>
              <Image
                alt="VR Virtual Reality TrackGenesis"
                width={{ base: "210px", md: "210px", lg: "330px" }}
                objectFit={"contain"}
                zIndex={1}
                mr={{ base: "auto", md: "auto", lg: "0px" }}
                mb={{ base: "-180px", md: "-180px", lg: "0px" }}
                ml={{ base: "auto", md: "auto", lg: "-300px" }}
                src={BlkRing}
              ></Image>
            </Flex>

            {/* Training */}
            <VRTraining />

            {/* Meeting */}
            <VirtualMeeting />

            {/* Gaming */}
            <Gaming />

            {/* Learning */}
            <VRLearning />
          </Flex>
          {/* Mixed Reality Intro */}

          <Flex
            my={{ base: "30px", md: "30px", lg: "90px" }}
            // p={"60px"}
            mx={"auto"}
            width={{ base: "80%", md: "80%", lg: "900px" }}
            flexDir={{
              base: "column-reverse",
              md: "column-reverse",
              lg: "row",
            }}
          >
            <Flex
              id="mixedreality"
              flexDir={"column"}
              mt={{ base: "-60px", md: "-50px", lg: "0px" }}
              justifyContent={"center"}
              alignItems={"center"}
              backgroundColor={"rgba(0, 0, 0, 0.15)"}
              borderRadius={"5px"}
              backdropFilter="auto"
              backdropBlur="2px"
              boxShadow={"0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"}
            >
              <Flex
                flexDir={"column"}
                mr={"auto"}
                ml={{ base: "20px", md: "20px", lg: "45px" }}
                mt={{ base: "100px", md: "100px", lg: "30px" }}
              >
                <Text
                  className="heading"
                  color={"#94DAF8"}
                  fontSize={{ base: "22px", md: "22px", lg: "25px" }}
                  pr={"30px"}
                >
                  Mixed Reality
                </Text>
                <Image
                  mt={"-10px"}
                  ml={"-10px"}
                  width={"70px"}
                  src={Underline}
                  alt="underline"
                ></Image>
              </Flex>
              <Text
                pr={{ base: "20px", md: "30px", lg: "150px" }}
                pl={{ base: "20px", md: "30px", lg: "50px" }}
                py={{ base: "30px", md: "30px", lg: "30px" }}
                fontSize={{ base: "14px", md: "14px", lg: "17px" }}
                color={"white"}
                className="content_font"
                fontWeight={"light"}
                maxW={{ base: "600px", md: "600px", lg: "800px" }}
              >
                Imagine if you could interact with virtual elements in the real
                world!
                <br />
                <br />
                Blending the physical and digital realms opens up new
                possibilities for MR in business. Mixed reality makes it
                possible to hold interactive virtual conferences, hands-on
                training in a simulated environment that transcends geographical
                barriers, and dynamic data visualization.
              </Text>
            </Flex>

            <Image
              mr={{ base: "auto", md: "auto", lg: "0px" }}
              alt=" Mixed Reality TrackGenesis"
              ml={{ base: "auto", md: "auto", lg: "-150px" }}
              zIndex={2}
              width={{ base: "180px", md: "180px", lg: "280px" }}
              objectFit={"contain"}
              src={MRnew}
            ></Image>
            <Image
              alt=" Mixed Reality TrackGenesis"
              width={{ base: "210px", md: "210px", lg: "330px" }}
              objectFit={"contain"}
              zIndex={1}
              mr={{ base: "auto", md: "auto", lg: "0px" }}
              mb={{ base: "-180px", md: "-180px", lg: "0px" }}
              ml={{ base: "auto", md: "auto", lg: "-300px" }}
              src={BlkRing}
            ></Image>
          </Flex>
        </Flex>
        <ParticleBackground />
        <Footer />
      </Flex>
    </>
  );
}

export default ArVr;
